<template>
    <div class="rounded-t mb-0 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h1 class="mb-6 text-5xl font-bold text-white leading-snug">
            Dashboard
          </h1>
        </div>
        <div class="relative w-full max-w-full flex-grow flex-1 text-right">
          <router-link to="/shop">
          <div
              class="p-2 bg-brand-button items-center text-indigo-100 leading-none rounded-full flex inline-flex"
          >
            <span
                class="flex rounded-full bg-blue-800 uppercase px-2 py-1 text-xs font-bold mr-3"
            >SHOP</span
            >
            <span class="font-semibold mr-2 text-left flex-auto"
            >Visit our store to buy your own prefixes!</span
            >
            <svg
                class="fill-current opacity-75 h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
            >
              <path
                  d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"
              />
            </svg>
          </div>
          </router-link>
        </div>
      </div>
    </div>
  <div class="bg-brand-white10 rounded-xl">
    <div class="mx-auto py-3 px-3 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between flex-wrap">
        <div class="w-0 flex-1 flex items-center">
          <span class="flex">
            <img v-if="this.user.uuid !== undefined" :src="`https://render.hdskins.de/users/skin/${this.user.uuid}/face`" class="mx-auto object-cover h-10 w-10"/>
          </span>
          <p class="ml-3 font-medium text-white truncate">
            <span> Welcome {{ this.user.name }} </span>
          </p>
        </div>
        <div
            class="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto"
        >
          <a class="flex items-center justify-center px-4 py-2">
            <img src="@/assets/images/coin.svg"/>
            <span class="px-1 font-medium">{{ this.user.coins }} GCoins</span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-wrap mt-4">
    <div class="w-full xl:w-8/12 mb-12 xl:mb-0 lg:pr-4">
      <main class="w-full flex-grow">
        <main class="h-full overflow-y-auto">
          <div class="container mx-auto grid">
            <div class="grid gap-4 mb-4 md:grid-cols-1 xl:grid-cols-3">
              <div class="flex items-center p-4 bg-brand-white10 rounded-lg shadow-lg">
                <div class="p-3 mr-4 text-white bg-brand-button rounded-lg">
                  <AnnotationIcon class="h-8 w-8" aria-hidden="true" />
                </div>
                <div>
                  <p class="mb-2 text-base font-medium text-white">Message Count</p>
                  <p v-if="this.user.uuid !== undefined" class="text-xl font-semibold text-white">{{ this.user.messageCount }}</p>
                </div>
              </div>
              <div class="flex items-center p-4 bg-brand-white10 rounded-lg shadow-lg">
                <div class="p-3 mr-4 text-white bg-brand-button rounded-lg">
                  <ClockIcon class="h-8 w-8" aria-hidden="true" />
                </div>
                <div>
                  <p class="mb-2 text-base font-medium text-white">Registration Time</p>
                  <p v-if="this.user.uuid !== undefined" class="text-xl font-semibold text-white">{{ this.moment(this.user.registrationTime).format("DD.MM.YYYY") }}</p>
                </div>
              </div>
              <div class="flex items-center p-4 bg-brand-white10 rounded-lg shadow-lg">
                <div class="p-3 mr-4 text-white bg-brand-button rounded-lg">
                  <TagIcon class="h-8 w-8" aria-hidden="true" />
                </div>
                <div>
                  <p class="mb-2 text-base font-medium text-white">Rank</p>
                  <p v-if="this.user.uuid !== undefined" class="text-xl font-semibold text-white">{{ this.user.rank }}</p>
                </div>
              </div>
            </div>
          </div>
        </main>
      </main>
      <div
          class="relative flex flex-col min-w-0 break-words bg-brand-white10 text-white w-full mb-4 shadow-lg rounded-xl"
      >
        <div class="rounded-t mb-0 px-3 py-3 border-0">
          <div class="flex flex-wrap items-center">
            <div class="relative w-full max-w-full flex-grow flex-1">
              <h3 class="font-semibold text-base text-blueGray-700">
                Select your prefix
              </h3>
            </div>
            <div>
              <div class="relative inline-flex">
                <svg
                    class="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 412 232"
                >
                  <path
                      d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z"
                      fill="#648299"
                      fill-rule="nonzero"
                  />
                </svg>
                <select
                    v-if="shopItemIds.length && Object.keys(shopItems).length"
                    v-model="itemId"
                    @change="setPrefix(itemId)"
                    class="items-center px-6 py-2 font-semibold text-white transition duration-500 ease-in-out transform bg-brand-button rounded-lg hover:bg-blueGray-900 focus:ring focus:outline-none"
                >
                  <option :value="-1" selected>None</option>
                  <option
                      v-for="item in this.shopItemIds"
                      v-bind:value="item"
                      :key="item"
                  >
                    {{ shopItems[item].name }}
                  </option>
                </select>

                <a
                    v-else
                    class="items-center px-6 py-2 font-semibold text-white transition duration-500 ease-in-out transform bg-brand-button rounded-lg hover:bg-blueGray-900 focus:ring focus:outline-none"
                >
                  No prefix available
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="block w-full overflow-x-auto"></div>
      </div>
      <div class="relative flex flex-col min-w-0 break-words bg-brand-white10 text-white w-full mb-4 shadow-lg rounded-xl">
        <div class="p-4">
          <h3 class="leading-6 font-semibold text-base text-white">
            External Services
          </h3>
          <div v-if="this.user.uuid !== undefined">
          <div v-if="this.user.discordUserInfo !== undefined" class="mt-5">
            <div class="rounded-md px-4 py-4 bg-auto bg-opacity-5 flex items-start justify-between" style="background-image: url('https://dl.globalchataddon.de/images/discord_background.png')">
              <div class="flex items-start">
                <img class="h-12 w-12 rounded-lg" :src="`https://cdn.discordapp.com/avatars/${this.user.discordUserInfo.id}/${this.user.discordUserInfo.avatar}.png`" alt="" /> <!---->
                <div class="mt-0 ml-4">
                  <div class="text-white font-bold">
                    Discord
                  </div>
                  <div class="text-white">
                    {{ this.user.discordUserInfo.username + "#" + this.user.discordUserInfo.discriminator }}
                  </div>
                </div>
              </div>
              <div class="mt-2 ml-6 flex-shrink-0">
                <button
                    class="items-center px-6 py-2 ml-3 font-semibold text-white transition duration-500 ease-in-out transform bg-brand-button rounded-lg hover:bg-blueGray-900 focus:ring focus:outline-none"
                    @click="refreshInfo"
                >
                  Refresh
                </button>
                <button
                    class="items-center px-6 py-2 ml-3 font-semibold text-white transition duration-500 ease-in-out transform bg-brand-button rounded-lg hover:bg-blueGray-900 focus:ring focus:outline-none"
                    @click="unlinkDiscord"
                >
                  Unlink
                </button>
              </div>
            </div>
          </div>
          <div v-else class="mt-5">
            <div class="rounded-md px-4 py-4 bg-auto bg-opacity-5 flex items-start justify-center" style="background-image: url('https://dl.globalchataddon.de/images/discord_background.png')">

              <button
                  class="items-center px-6 py-2 font-semibold text-white transition duration-500 ease-in-out transform bg-brand-button rounded-lg hover:bg-blueGray-900 focus:ring focus:outline-none"
                  @click="beginDiscordAuth()"
              >
                Link with Discord
              </button>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div class="relative flex flex-col min-w-0 break-words bg-brand-white10 text-white w-full mb-4 shadow-lg rounded-xl">
        <div class="p-4">
          <h3 class="leading-6 font-semibold text-base text-white">
            Share your GCoins
          </h3>
          <div class="mt-5">
            <div class="rounded-md px-4 py-4 bg-auto bg-opacity-5 flex items-start justify-between" style="background-image: url('https://dl.globalchataddon.de/images/gcoins_background.png')">
              <div class="flex items-start">
                <img class="h-12 w-12" src="@/assets/images/coin.svg"/>
                <div class="mt-0 ml-4">
                  <div class="text-white font-bold">
                    Specify the number of GCoins
                  </div>
                  <div class="text-white font-bold">
                    you want to send per player
                  </div>
                </div>
              </div>
              <div class="mt-2 ml-6 flex-shrink-0">
                <input type="number" placeholder="GCoins" v-model="gcoins"
                    class="items-center px-6 py-2 ml-3 font-semibold text-white placeholder-white transition duration-500 ease-in-out transform bg-brand-button rounded-lg hover:bg-blueGray-900 focus:ring focus:outline-none"
                />
                <button
                    class="items-center px-6 py-2 ml-3 font-semibold text-white transition duration-500 ease-in-out transform bg-brand-button rounded-lg hover:bg-blueGray-900 focus:ring focus:outline-none"
                    @click="sendGCoins()"
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--
      <div v-show="this.user.rank === 'Admin' || this.user.rank === 'Staff'"
          class="relative flex flex-col min-w-0 break-words bg-brand-white10 text-white w-full mb-6 shadow-lg rounded-xl"
      >
        <div class="rounded-t mb-0 px-3 py-3 border-0">
          <div class="flex flex-wrap items-center">
            <div class="relative w-full max-w-full flex-grow flex-1">
              <h3 class="font-semibold text-base text-blueGray-700">
                History
              </h3>
            </div>
          </div>
        </div>
        <div v-if="this.user.uuid !== undefined" class="block w-full overflow-x-auto">
          <table class="items-center w-full bg-transparent border-collapse">
            <thead>
            <tr>
              <th
                  class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-gray-900 py-3 text-xs uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Time
              </th>
              <th
                  class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-gray-900 py-3 text-xs uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Status
              </th>

            </tr>
            </thead>

            <tbody>
            <tr v-for="buyCoins in this.buyCoinsHistory" :key="buyCoins.timeStamp">
              <th
                  class="px-6 bg-blueGray-50 text-blueGray-500 align-middle py-3 text-xs uppercase whitespace-nowrap font-semibold text-left"
              >
                {{ this.moment(buyCoins.timeStamp).format("DD.MM.YYYY HH:mm") }}
              </th>
              <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle py-3 text-xs uppercase whitespace-nowrap font-semibold text-left">
                <div class="flex items-center">
                  <img src="@/assets/images/coin.svg"/>
                  <span class="px-1 font-medium">{{ buyCoins.extra }} GCoins</span>
                </div>
              </th>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      -->


      <div
          class="relative flex flex-col min-w-0 break-words bg-brand-white10 text-white w-full mb-6 shadow-lg rounded-xl"
      >
        <div class="rounded-t mb-0 px-3 py-3 border-0">
          <div class="flex flex-wrap items-center">
            <div class="relative w-full max-w-full flex-grow flex-1">
              <h3 class="font-semibold text-base text-blueGray-700">
                Ban History
              </h3>
            </div>
          </div>
        </div>
        <div v-if="this.user.uuid !== undefined" class="block w-full overflow-x-auto">
          <table class="items-center w-full bg-transparent border-collapse">
            <thead>
            <tr>
              <th
                  class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-gray-900 py-3 text-xs uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Expiration date
              </th>
              <th
                  class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-gray-900 py-3 text-xs uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Chatlog
              </th>
              <th
                  class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-gray-900 py-3 text-xs uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Reason
              </th>
              <th
                  class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-gray-900 py-3 text-xs uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Status
              </th>
            </tr>
            </thead>

            <tbody v-if="currentMute || muteHistory.length">
            <tr v-if="currentMute">
              <CurrentMuteComponent :currentMute="currentMute"/>
            </tr>
            <tr v-else></tr>
            <tr v-for="mute in this.muteHistory" :key="mute.chatLogId">
              <MuteComponent :mute="mute"/>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td
                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                You haven't been banned yet.
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="w-full xl:w-4/12">
      <div class="relative flex flex-col min-w-0 break-words bg-brand-white10 text-white w-full mb-4 shadow-lg rounded-xl">
        <div class="p-4">
          <h3 class="leading-6 font-semibold text-base text-white">
            Trade your
            <span class="text-yellow-300">LabyCoins</span>
            to
            <span class="text-yellow-300">GCoins</span>
          </h3>
          <p>You get 1:1 your LabyCoins in GCoins!</p>
          <div class="mt-5">
            <div class="rounded-md px-4 py-4 bg-auto bg-opacity-5 flex items-start justify-between"  style="background-image: url('https://dl.globalchataddon.de/images/labycoins_background.png'); background-repeat: no-repeat;
    background-size: 100%;">
              <div class="flex items-start">
                <img class="h-12 w-12" src="https://www.labymod.net/page/tpl/assets/images/labycoin_icon.png"/>
                <div class="mt-0 ml-4">
                  <div class="text-white font-bold">
                    Send your LabyCoins
                  </div>
                  <div class="text-white font-bold">
                    to the player "GlobalChatAddon".
                  </div>
                </div>
              </div>
              <div class="mt-2 ml-6 flex-shrink-0">
                <button
                    class="items-center px-6 py-2 ml-3 font-semibold text-white transition duration-500 ease-in-out transform bg-brand-button rounded-lg hover:bg-blueGray-900 focus:ring focus:outline-none"
                    @click="labymod()"
                >
                  Trade
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="relative flex flex-col min-w-0 break-words bg-brand-white10 w-full mb-6 shadow-lg rounded-xl"
      >
        <div class="rounded-t mb-0 px-4 py-3 border-0">
          <div class="flex flex-wrap items-center">
            <div class="relative w-full max-w-full flex-grow flex-1">
              <h3 class="font-semibold text-base text-blueGray-700">
                Your login streak »
                <span class="text-yellow-500">{{ this.user.streak }} Days</span>
              </h3>
              <p>You will be rewarded with 10 GCoins for daily activity!</p>
            </div>
          </div>
        </div>
        <div v-if="this.user.uuid !== undefined" class="block w-full overflow-x-auto">
          <table class="items-center w-full bg-transparent border-collapse">
            <thead class="thead-light">
            <tr>
              <th
                  class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-gray-900 py-3 text-xs uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                DAYS
              </th>
              <th
                  class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-gray-900 py-3 text-xs uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Reward
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(coins, days) in this.streaks" :key="days">
              <td
                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <div class="flex items-center">
                  <img
                      v-if="this.user.streak >= days"
                      class="h-6 w-6"
                      src="@/assets/images/streak.gif"
                  />
                  <img v-else class="h-6 w-6" src="@/assets/images/streak_not.gif"/>
                <span class="px-1 font-medium">{{ days }} Days</span>
                </div>
              </td>
              <td
                  class="border-t-0 flex items-center px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <img v-if="this.user.streak >= days" src="@/assets/images/coin.svg"/>
                <img v-else src="@/assets/images/coin_not.svg"/>
                <span class="px-1 font-medium">{{ coins }} GCoins</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <NotificationComponent/>
</template>

<script>
import MuteComponent from "@/components/MuteComponent.vue";
import CurrentMuteComponent from "@/components/CurrentMuteComponent.vue";
import NotificationComponent from "@/components/NotificationComponent.vue";
import {
  AnnotationIcon,
  ClockIcon,
  TagIcon
} from '@heroicons/vue/outline'
import moment from "moment";

import {loadItems, shopItems} from '../items';

export default {
  name: "DashboardComponent",
  components: { MuteComponent, CurrentMuteComponent, NotificationComponent, AnnotationIcon, ClockIcon, TagIcon },
  data: () => ({
    itemId: -1,
    user: {},
    currentMute: {},
    muteHistory: [],
    shopItemIds: [],
    streaks: {},
    gcoins: "",
    buyCoinsHistory: [],
    moment: moment,
    shopItems: shopItems
  }),
  methods: {
    labymod: function () {
      window.open("https://www.labymod.net/de/coins", "_blank");
    },
    beginDiscordAuth() {
      window.open("https://discord.com/api/oauth2/authorize?client_id=820101918470045706&redirect_uri=https%3A%2F%2FGlobalChatAddon.de%2Fdashboard%2Fdiscord%2Foauth&response_type=code&scope=guilds.join%20identify", "_blank")
    },
    async sendGCoins() {
      try {
        await fetch("https://api.globalchataddon.de/dashboard/coins/gift/" + this.gcoins, {
          method: "POST",
          headers: {
            token: this.$cookies.get("GlobalChat-Login"),
          },
        }).then(value => {
          if (value.status === 200) {
            this.$notify(
                {
                  title: "Successful",
                  text: "You have successfully sent each player " + this.gcoins + " Gcoins.",
                  type: "warning",
                  group: "successfulNotification",
                },
                4000
            )
          } else if (this.gcoins <= 0){
            this.$notify(
                {
                  title: "Error",
                  text: "You have not entered a valid amount!",
                  type: "warning",
                  group: "errorNotification",
                },
                4000
            );
          } else {
            this.$notify(
                {
                  title: "Error",
                  text: "You have too few Gcoins!",
                  type: "warning",
                  group: "errorNotification",
                },
                4000
            );
          }
        });
      } catch (error) {
        console.error("Failed to unlink discord", error);
      }
    },
    async unlinkDiscord() {
      try {
        await fetch("https://api.globalchataddon.de/dashboard/discord/oauth/unlink", {
          method: "POST",
          headers: {
            token: this.$cookies.get("GlobalChat-Login"),
          },
        }).then(value => {
          if (value.status === 200) {
            this.$router.go(0);
          } else {
            this.$notify(
                {
                  title: "Error",
                  text: "Unlink failed!",
                  type: "warning",
                  group: "errorNotification",
                },
                4000
            );
          }
        });
      } catch (error) {
        console.error("Failed to unlink discord", error);
      }
    },
    async refreshInfo() {
      try {
        await fetch("https://api.globalchataddon.de/dashboard/discord/oauth/refresh", {
          method: "POST",
          headers: {
            token: this.$cookies.get("GlobalChat-Login"),
          },
        }).then(value => {
          if (value.status === 200) {
            this.$router.go(0);
          } else {
            this.$notify(
                {
                  title: "Error",
                  text: "Unlink failed!",
                  type: "warning",
                  group: "errorNotification",
                },
                4000
            );
          }
        });
      } catch (error) {
        console.error("Failed to refresh info", error);
      }
    },
    async setPrefix(itemId) {
      try {
        await fetch("https://api.globalchataddon.de/prefix/" + itemId, {
          method: "POST",
          headers: {
            token: this.$cookies.get("GlobalChat-Login"),
          },
        });
      } catch (error) {
        console.error("Failed to request change prefix", error);
      }
    },
    async loadUserInfos() {
      try {
        const response = await fetch("https://api.globalchataddon.de/userinfo", {
          method: "POST",
          headers: {
            token: this.$cookies.get("GlobalChat-Login"),
          },
        });
        const json = await response.json();

        this.user.prefix = json.prefix;
        this.user.uuid = json.uuid;
        this.user.rank = json.rank;
        this.user.name = json.name;
        this.user.coins = json.coins;
        this.user.streak = json.streak;
        this.user.messageCount = json.messageCount;
        this.user.registrationTime = json.registrationTime;

        if (json.discordUserInfo !== undefined) {
          this.user.discordUserInfo = json.discordUserInfo;
        }

        if (json.currentMute !== null) {
          this.currentMute = json.currentMute;
        }

        this.muteHistory = json.muteHistory.reverse();
        this.buyCoinsHistory = json.buyCoinsHistory;

        this.shopItemIds = json.shopItems;
        this.itemId = json.activeItem !== undefined ? json.activeItem : -1
      } catch (error) {
        console.error("Failed to request user infos", error);
      }
    },
    async loadStreakInfos() {
      try {
        const response = await fetch(
            "https://api.globalchataddon.de/dailyRewards"
        );
        const json = await response.json();

        this.streaks = json;
      } catch (error) {
        console.error("Failed to request streak infos", error);
      }
    },
  },
  async mounted() {
    this.loadUserInfos();
    this.loadStreakInfos();
    loadItems().then(() => this.$forceUpdate());
  },
};
</script>