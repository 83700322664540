<template>
  <notificationGroup group="errorNotification" position="bottom">
    <div
        class="fixed inset-x-0 bottom-0 flex px-4 py-6 pointer-events-none p-6 items-start justify-end"
    >
      <div class="max-w-sm w-full">
        <notification v-slot="{notifications}">
          <div
              class="flex max-w-sm w-full mx-auto bg-red-500 shadow-md rounded-lg overflow-hidden mt-4"
              v-for="notification in notifications"
              :key="notification.id"
          >
            <div class="flex justify-center items-center w-12 bg-red-500">
              <svg class="h-6 w-6 fill-current text-white" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                <title>Alert Circle</title>
                <path
                    d='M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm0 319.91a20 20 0 1120-20 20 20 0 01-20 20zm21.72-201.15l-5.74 122a16 16 0 01-32 0l-5.74-121.94v-.05a21.74 21.74 0 1143.44 0z'/>
              </svg>
            </div>

            <div class="-mx-3 py-2 px-4">
              <div class="mx-3">
                <span class="text-white font-semibold">{{ notification.title }}</span>
                <p class="text-gray-100 text-sm">{{ notification.text }}</p>
              </div>
            </div>
          </div>
        </notification>
      </div>
    </div>
  </notificationGroup>

  <notificationGroup group="successfulNotification" position="bottom">
    <div
        class="fixed inset-x-0 bottom-0 flex px-4 py-6 pointer-events-none p-6 items-start justify-end"
    >
      <div class="max-w-sm w-full">
        <notification v-slot="{notifications}">
          <div
              class="flex max-w-sm w-full mx-auto bg-green-500 shadow-md rounded-lg overflow-hidden mt-4"
              v-for="notification in notifications"
              :key="notification.id"
          >
            <div class="flex justify-center items-center w-12 bg-green-500">
              <svg class="h-6 w-6 fill-current text-white" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                <title>Checkmark Circle</title>
                <path
                    d='M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29l-134.4 160a16 16 0 01-12 5.71h-.27a16 16 0 01-11.89-5.3l-57.6-64a16 16 0 1123.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0124.5 20.58z'/>
              </svg>
            </div>

            <div class="-mx-3 py-2 px-4">
              <div class="mx-3">
                <span class="text-white font-semibold">{{ notification.title }}</span>
                <p class="text-gray-100 text-sm">{{ notification.text }}</p>
              </div>
            </div>
          </div>
        </notification>
      </div>
    </div>
  </notificationGroup>
</template>