<template>
  <div class="dashboard">
    <div v-if="authorized">
      <DashboardComponent/>
    </div>
    <div v-else-if="!authorized">
      <RegisterComponent/>
    </div>
    <div v-else></div>
  </div>
</template>

<script>
import DashboardComponent from "@/components/DashboardComponent.vue";
import RegisterComponent from "@/components/RegisterComponent.vue";
import {useAuthorization} from "../js/authorization.js"

export default {
  name: "Dashboard",
  components: {DashboardComponent, RegisterComponent},
  data() {
    const {authorized, checkAuthorization, changeAuthorization} = useAuthorization(false);

    return {
      authorized: authorized,
      loginRequest: "",
      checkAuthorization: checkAuthorization,
      changeAuthorization: changeAuthorization,
    }
  },
  methods: {
    async login() {
      const token = this.$route.query["token"];
      const uuid = this.$route.query["uuid"];

      try {
        fetch("https://api.globalchataddon.de/dashboard/cookie/" + uuid + "/create", {
          method: "POST",
          headers: {
            token: token
          },
        }).then((response) => {
          if (response.status === 200) {
            response.text().then((text) => {
              this.loginRequest = "success";
              this.changeAuthorization(true);
              this.$cookies.set("GlobalChat-Login", text, -1, "/", ".globalchataddon.de");
              this.$router.push({path: "/dashboard"});
            });
          } else {
            this.loginRequest = "failed";
            this.infoMessage = "Invalid register token";
          }
        });
      } catch (error) {
        console.error("Failed to request cookie", error);
      }
    },
  },
  async mounted() {
    if (
        this.$route.query.token !== undefined &&
        this.$route.query.uuid !== undefined
    ) {
      this.login();
    } else {
      this.checkAuthorization(this.$cookies.get("GlobalChat-Login"))
    }
  },
};
</script>
